<template>
    <b-container class="dashboad bg-light page-wrapper">
        <b-row class="main-content-wrapper" no-gutters>
            <b-col cols="12">
                <h1>Notification</h1>
            </b-col>
            <b-col>
                <b-button variant="success" @click="showNotification">Click to show notification</b-button>
            </b-col>
            <notification-component ref="notificationComp">
              <template #message>{{ $t('common.refresh_message') }}</template>
            </notification-component>
        </b-row>
    </b-container>
</template>

<script>
import NotificationComponent from '@/components/common/NotificationComponent'
// import EventBus from '@/event-bus'

export default {
  name: 'NotificationExperiment',
  components: { NotificationComponent },
  methods: {
    showNotification () {
      this.$refs.notificationComp.handleShow()
    }
  }
}
</script>
